.expt-playlistButton {
  height: 3.13vw;
  width: 18.23vw;
  object-fit: cover;
  background-color: #E3DBE6;
  padding-left: 1.041vw;
  padding-right: 1.041vw;
}

.expt-playlistButton:hover {
  cursor: pointer;
}

.expt-playlist-button-icon {
  width: 1.25vw;
}

.expt-playlistButton-items {
  position: absolute;
  bottom: 100%;
}

.expt-playlistButton-container {
  position: relative;
}


.expt-playlistButton-items > *:not(:last-child) {
  border-bottom: 1px solid #E3DBE6;
}

@media (min-width: 768px) {
  .ki-about-grid-square-amber-img-1,
  .ki-about-grid-square-amber-img-2,
  .ki-about-grid-square-amber-img-3 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ki-grid-square {
    height: 100%;
    width: 100%;
  }
}

.ki-grid-square {
  opacity: 0;
}

.ki-grid-square:hover {
  opacity: 1;
  transition-property: opacity;
  transition-duration: .5s;
  transition-timing-function: ease-in;
}

@media (max-width: 767px) {
  .ki-about-grid-square-amber-img-1,
  .ki-about-grid-square-amber-img-2,
  .ki-about-grid-square-amber-img-3 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ki-grid-square {
    height: 100%;
    width: 100%;
  }
}

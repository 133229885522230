@media (min-width: 768px) {
  .ki-madsane-banner-container {
    padding-top: 4.296875vw;
    padding-bottom: 7.65625vw;
  }

  .ki-madsane-banner-globe-tl {
    top: 8.8281vw;
    left: 7.1875vw;
  }

  .ki-madsane-banner-sparkles-tl {
    top: 8.8281vw;
    left: 12.7604vw;
  }

  .ki-madsane-banner-globe-br {
    bottom: 11.61458vw;
    right: 5.677083vw;
  }

  .ki-madsane-banner-sparkles-br {
    bottom: 11.61458vw;
    right: 11.25vw;
  }

  .ki-madsane-banner-globe-tl,
  .ki-madsane-banner-sparkles-tl,
  .ki-madsane-banner-globe-br,
  .ki-madsane-banner-sparkles-br {
    width: 5.5729vw;
  }

  .ki-madsane-banner-link {
    width: 17.3958vw;
    height: 4.166666vw;
    margin-top: 4.2708vw;
  }
}

@media (max-width: 767px) {
  .ki-madsane-banner-container {
    padding-top: 20vw;
    padding-bottom: 20vw;
  }

  .ki-madsane-banner-globe-tl {
    top: 8.8281vw;
    left: 7.1875vw;
  }

  .ki-madsane-banner-sparkles-tl {
    top: 8.8281vw;
    left: 18.7604vw;
  }

  .ki-madsane-banner-globe-br {
    bottom: 11.61458vw;
    right: 7.1875vw;
  }

  .ki-madsane-banner-sparkles-br {
    bottom: 11.61458vw;
    right: 18.25vw;
  }

  .ki-madsane-banner-globe-tl,
  .ki-madsane-banner-sparkles-tl,
  .ki-madsane-banner-globe-br,
  .ki-madsane-banner-sparkles-br {
    width: 10vw;
  }

  .ki-madsane-banner-link {
    width: 34vw;
    height: 9vw;
    margin-top: 10VW;
  }
}

.ki-madsane-banner-link {
  border-radius: 4px;
}

.ki-madsane-banner-container {
  position: relative;
}

.ki-madsane-banner-globe-tl,
.ki-madsane-banner-sparkles-tl,
.ki-madsane-banner-globe-br,
.ki-madsane-banner-sparkles-br {
  position: absolute;
}

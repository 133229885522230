@media (min-width: 768px) {
  .ki-restage-hero-container {
    width: 100%;
    height: calc(100vh - 5.20833vw);
    min-height: 700px;
    padding-top: 4.0625vw;
  }

  .ki-restage-hero-img {
    width: 62.5vw;
  }

  .ki-restage-hero-logo {
    width: 58.59375vw;
  }

  .ki-restage-hero-text {
    padding-right: 2.1875vw;
    padding-bottom: 2.55208vw;
  }

  .ki-restage-hero {
    background-image: linear-gradient(to bottom, #3E0359, #EB5B49);
    position: relative;
    height: 100vh;
    min-height: 800px;
  }

  .ki-restage-hero-image {
    top: 6.38vw;
    left: 20.94vw;
    object-fit: cover;
    width: 59.1vw;
    position: absolute;
  }
}

.ki-restage-hero-text {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .5s;
}

.ki-restage-hero-text:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .ki-restage-hero-img {
    width: 62.5vw;
  }

  .ki-restage-hero {
    background-image: linear-gradient(to bottom, #3E0359, #EB5B49);
    position: relative;
    height: 100vh;
    min-height: 800px;
  }

  .ki-restage-hero-image {
    top: 6.38vw;
    left: 20.94vw;
    object-fit: cover;
    width: 59.1vw;
    position: absolute;
  }
  .ki-restage-hero-logo {
    width: 80vw;
  }
}

.ki-restage-hero-container {
  position: relative;
  background-image: linear-gradient(180deg, #3E0359 0%, #EB5B49 100%);
}

.ki-restage-hero-img {
  position: absolute;
}

.ki-restage-hero-text {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  line-height: 1;
  width: 100%;
  height: 100%;
}

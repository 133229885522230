
@media (min-width: 768px) {
  .ki-madsane-listen-iframe-wrapper {
    width: 47.8125vw;
    height: 320px;
    padding-top: 6.0416666vw;
    margin-left: 3vw;
    margin-top: 12vw;
  }

  .ki-madsane-listen-container {
    position: relative;
    width: 100%;
  }

  .ki-madsane-listen-galaxy-img {
    width: 100%;
    height: 100%;
  }
  .ki-madsane-listen-contents {
    width: 100%;
  }

  .ki-madsane-listen-cta-message {
    transform: rotate(-15.95deg);
    padding-left: 5vw;
    padding-top: 18vw;
  }

  .ki-madsane-listen-planet {
    width: 13.520833vw;
    left: 31.4583vw;
    top: 5.05208vw;
  }
}

@media (max-width: 767px) {
  .ki-madsane-listen-iframe-wrapper {
    top: 80vw;
    position: absolute;
    width: 80vw;
    left: 10vw;
    height: 217px;
  }

  .ki-madsane-listen-container {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 700px
  }

  .ki-madsane-listen-galaxy-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .ki-madsane-listen-contents {
    width: 100%;
    height: 100vh;
  }

  .ki-madsane-listen-cta-message {
    transform: rotate(-15.95deg);
    padding-left: 5vw;
    padding-top: 18vw;
  }

  .ki-madsane-listen-planet {
    width: 13.520833vw;
    left: 31.4583vw;
    top: 5.05208vw;
  }
}

.ki-madsane-listen-galaxy-img, .ki-madsane-listen-planet {
  position: absolute;
}

.ki-madsane-listen-contents {


}

.ki-madsane-listen-contents {
  position: relative;
  z-index: 1;
}

.ki-madsane-listen-container {
  overflow: hidden;
}

@media (min-width: 768px) {
  .ki-brandvibes-container {
    width: 100%;
    padding-bottom: 1.302vw;
    position: relative;
    z-index: 2;
    padding-top: 5vw;
  }

  .ki-brandvibes-header {
    position: relative;
    z-index: 2;
  }

  .ki-brandvibes-main {
    width: 65.2083vw;
    position: relative;
    z-index: 2;
  }

  .ki-brandvibes-mission {
    margin-bottom: 2.083vw;
  }

  .ki-brandvibes-mission-label {
    margin-bottom: .52083vw;
  }

  .ki-brandvibes-values-subheader {
    margin-top: 3.6458vw;
    margin-bottom: 4.1666vw;
  }

  .ki-brandvibes-ribbon {
    width: 10.46vw;
    position: absolute;
    bottom: 11.3958vw;
    left: 2.66666vw;
    z-index: 3;
  }
}

.ki-brandvibes-container {
  width: 100%;
  padding-bottom: 1.302vw;
  position: relative;
  z-index: 2;
  padding-top: 5vw;
}

.ki-brandvibes-header {
  position: relative;
  z-index: 2;
}

.ki-brandvibes-main {
  width: 65.2083vw;
  position: relative;
  z-index: 2;
}

.ki-brandvibes-mission {
  margin-bottom: 2.083vw;
}

.ki-brandvibes-mission-label {
  margin-bottom: .52083vw;
}

.ki-brandvibes-img {
  width: 100vw;
  position: absolute;
  left: 0vw;
  z-index: 1;
}

.ki-brandvibes-img-top {
  top: 0vw;
}

.ki-brandvibes-img-bottom {
  bottom: 0vw;
}

.ki-brandvibes-purpose {
  margin-top: 4.16vw;
}

@media (max-width: 767px) {
  .ki-brandvibes-mission-description {
    margin-top: 2vw;
    margin-bottom: 2vw;
  }

  .ki-brandvibes-mission-description > *:first-child {
    margin-bottom: 2vw;
  }

  .ki-brandvibes-values-description  {
    margin-bottom: 2vw;
  }

  .ki-brandvibes-target {
    margin-bottom: 10px;
  }
  .ki-brandvibes-ribbon {
    width: 10.46vw;
    position: absolute;
    bottom: 10.3958vw;
    right: 5.66666vw;
    z-index: 3;
    object-fit: cover;
  }
}

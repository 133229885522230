.ki-footer-main {
  position: relative;
}
@media (min-width: 768px) {
  .ki-footer-container {
    padding-left: 5.1041vw;
    padding-right: 5.1041vw;
    padding-top: 8.35052083333vw;
    padding-bottom: 3.125vw;
  }

  .ki-footer-main {
    background-color: rgba(227, 219, 230, 0.25);
    border-radius: 9px;
    height: 19.166vw;
  }

  .ki-footer-social-links {
    right: 12.3958vw;
    top: 6.25vw;
    position: absolute;
  }

  .ki-footer-social-links > *:not(:last-child) {
    margin-right: 1.822916vw;
  }

  .ki-footer-social-link {
    height: 3.33333vw;
  }

  .ki-footer-textfieldbuttongroup {
    border-radius: 4px;
    height: 3.333333vw;
    border: 1px solid white;
    margin-bottom: 3.802083vw;
  }
}

@media (max-width: 767px) {
  .ki-footer-container {
    padding-left: 5.1041vw;
    padding-right: 5.1041vw;
    padding-top: 8.35052083333vw;
    padding-bottom: 3.125vw;
  }

  .ki-footer-main {
    background-color: rgba(227, 219, 230, 0.25);
    border-radius: 9px;
    padding: 5vw;
  }

  .ki-footer-social-links {
    margin-bottom: 5vw;
  }

  .ki-footer-social-links > *:not(:last-child) {
    margin-right: 5.822916vw;
  }

  .ki-footer-social-link {
    height: 8.33333vw;
  }

  .ki-footer-textfieldbuttongroup {
    border-radius: 4px;
    height: 9.333333vw;
    border: 1px solid white;
    margin-bottom: 3.802083vw;
  }
}

.ki-footer-container {
  position: relative;
  z-index: 5;
}

.ki-footer-button {
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 9px;
}

@media (min-width: 768px) {
  .ki-about-carousel-container {
    background-image: radial-gradient(95.73% 95.73% at 50% 50%, #583FBF 0%, #100C23 100%);
    height: 50vw;/*50.05208vw;*/
    min-height: 700px;
  }

  .ki-about-carousel-container-imgs > * {
    margin-right: 11.125vw;
  }

  .ki-whitecoat {
    width: 23.4640625vw;
    margin-left: 5vw;
  }

  .ki-orangebackround {
    width: 37.8369791667vw;
  }

  .ki-cafe-laptop {
    width: 31.2890625vw;
  }

  .ki-visionboard {
    width: 55.625vw;
  }

  .ki-santacon {
    width: 23.5036458vw;
  }

  .ki-cafe {
    width: 31.2890625vw;
  }

  .ki-beer {
    width: 23.5036458vw;
  }

  .ki-shoes {
    width: 28.5036458vw;
    padding-right: 5vw;
  }

  .ki-about-headphones {
    position: absolute;
    top: -6vw;
    left: 5vw;
    width: 35vw;
  }
}

.ki-about-carousel-container-imgs {
  padding-top: 5vw;
  padding-bottom: 5vw;
  padding-right: 5vw;

}

.ki-again-img {
  object-fit: cover;
}

@media (max-width: 767px) {
  .ki-about-headphones {
    position: absolute;
    top: -6vw;
    left: 5vw;
    width: 35vw;
  }
  .ki-about-carousel-container {
    background-image: radial-gradient(95.73% 95.73% at 50% 50%, #583FBF 0%, #100C23 100%);
    height: 95vw;
  }

  .ki-about-carousel-container-imgs > * {
    margin-right: 16.125vw;
  }

  .ki-whitecoat {
    width: 46.4640625vw;
    margin-left: 5vw;
  }

  .ki-orangebackround {
    width: 74.8369791667vw;
  }

  .ki-cafe-laptop {
    width: 62.2890625vw;
  }

  .ki-visionboard {
    width: 95.625vw;
  }

  .ki-santacon {
    width: 46.5036458vw;
  }

  .ki-cafe {
    width: 62.2890625vw;
  }

  .ki-beer {
    width: 46.5036458vw;
  }

  .ki-shoes {
    width: 51.5036458vw;
    padding-right: 5vw;
  }
}

.ki-about-carousel-container {
  position: relative;
}


.ki-about-carousel-container-imgs {
  position: relative;
    overflow-x: scroll;
  z-index: 2;
}

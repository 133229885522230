@media (min-width: 768px) {
  .ki-info-text {
    width: 65.20833vw;
    margin-left: 4.21875vw;
  }

  .ki-info-header {
    width: 26.30208vw;
  }
}

@font-face {
  font-family: Raustila;
  font-style: normal;
  font-weight: normal;
  src: url(fonts/raustila-Regular.otf);
}

@font-face {
  font-family: champagne-limousines;
  src: url(fonts/champagne_limousines/ChampagneAndLimousines.ttf) format('truetype');
}

@font-face {
  font-family: champagne-limousines-bold;
  src: url(fonts/champagne_limousines/ChampagneAndLimousinesBold.ttf) format('truetype');
}

@font-face {
  font-family: champagne-limousines-bold-italic;
  src: url(fonts/champagne_limousines/ChampagneAndLimousinesBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: champagne-limousines-italic;
  src: url(fonts/champagne_limousines/ChampagneAndLimousinesItalic.ttf) format('truetype');
}


.ki-raustila {
  font-family: Raustila;
}

.ki-champagne-limousines {
  font-family: champagne-limousines;
}

.ki-poppins {
  font-family: Poppins;
}

.ki-roboto {
  font-family: Roboto;
}

.ki-montserrat {
  font-family: Montserrat;
}

.ki-text-align-center {
  text-align: center;
}

@media (min-width: 768px) {
  .ki-text-align-end-dk {
    text-align: right;
    text-align: end;
  }
  .ki-font-244 {
    font-size: 12.7083vw;
  }

  .ki-font-224 {
    font-size: 11.666vw;
  }

  .ki-font-144 {
    font-size: 7.5vw;
  }

  .ki-font-96 {
    font-size: 5vw;
  }

  .ki-font-72 {
    font-size: 3.75vw;
  }

  .ki-font-64 {
    font-size: 3.3333vw;
  }

  .ki-font-40 {
    font-size: 2.0833vw;
  }

  .ki-font-28 {
    font-size: 1.45833vw;
  }

  .ki-font-24 {
    font-size: 1.25vw;
  }

  .ki-font-16 {
    font-size: 0.8333vw;
  }

  .ki-font-14 {
    font-size: 0.729166vw;
  }

  .ki-text-align-justify-dk {
    text-align: justify;
  }
}

@media (max-width: 767px) {
  .ki-text-align-start-mb {
    text-align: start;
    text-align: left;
  }
  .ki-font-8-mb {
    font-size: 2.6666vw;
  }
  .ki-font-14-mb {
    font-size: 3.73vw;
  }

  .ki-font-16-mb {
    font-size: 4.26666vw;
  }

  .ki-font-20-mb {
    font-size: 5.3333vw;
  }

  .ki-font-24-mb {
    font-size: 6.4vw;
  }

  .ki-font-25-mb {
    font-size: 6.666666vw;
  }

  .ki-font-28-mb {
    font-size: 7.46666vw;
  }

  .ki-font-30-mb {
    font-size: 8vw;
  }

  .ki-font-40-mb {
    font-size: 10.6666vw;
  }

  .ki-font-48-mb {
    font-size: 12.8vw;
  }

  .ki-font-50-mb {
    font-size: 13.333vw;
  }

  .ki-font-60-mb {
    font-size: 16vw;
  }

  .ki-font-81-mb {
    font-size: 21.6vw;
  }

  .ki-font-122-mb {
    font-size: 32.5333vw;
  }
}

.ki-text-align-center {
  text-align: center;
}

.ki-text-align-end {
  text-align: right;
  text-align: end;
}

.ki-text-align-justify {
  text-align: justify;
}

.ki-font-white, a.ki-font-white, a.ki-font-white:hover {
  color: white;
}

a.ki-font-white:hover {
  text-decoration: none;
}

.ki-font-green {
  color: #87BF3F;
}

.ki-font-100C23 {
  color: #100C23;
}

.ki-font-bold {
  font-weight: bold;
}

.ki-font-weight-700 {
  font-weight: 700;
}

.ki-font-semibold {
  font-weight: 600;
}

.ki-font-medium {
  font-weight: 500;
}

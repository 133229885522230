.ki-navbar-container-menu-open {
  background-color: transparent !important;
}


@media (min-width: 768px) {
  .ki-navbar-logo {
    width: 9.47916vw;
  }

  .ki-navbar-container {
    height: 5.20833vw;
    padding-left: 2.0833vw;
    padding-right: 2.0833vw;
  }

  .ki-navbar-hoverable {
    opacity: 0;
  }

  .ki-navbar-hoverable:hover {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .ki-navbar-logo {
    width: 25vw;
  }

  .ki-navbar-container {
    height: 15vw;
    padding-left: 2.0833vw;
    padding-right: 2.0833vw;
  }


  .ki-navbar-hoverable {
    opacity: 1;
  }
}

.ki-navbar-container {
  position: fixed;
  top: 0px;
  z-index: 7;
}
.ki-navbar-container {
  width: 100%;

  /*position: relative;
  z-index: 3*/;
}

.ki-navbar-hoverable {
  transition-property: background-color;
  transition-duration: .5;
}

@media (min-width: 768px) {
  .ki-about-top-text-section {
    padding-top: 4.16666vw;
    padding-right: 2.1875vw;
    /*padding-left: 2.0833vw;*/
    padding-bottom: 4.16666vw;
  }

  .ki-about-bottom-text-section {
    padding-right: 2.1875vw;
    padding-left: 2.0833vw;
    padding-bottom: 4.16666vw;
    padding-top: 4.16666vw;
  }

  .ki-about-details {
    width: 26.30208vw;
  }

  .ki-about-main-section {
    width: 65.20833vw;
    margin-left: 4.21875vw;
  }

  .ki-about-headline {
    margin-bottom: 2.1875vw;
    text-align: end;
    text-align: right;
  }

  .ki-about-details > *:not(:last-child) {
    margin-bottom: 1.666666vw;
  }

  .ki-about-divider {
    width: 13.02083vw;
    margin-top: 4.166vw;
    margin-bottom: 4.166vw;
  }

  .ki-about-divider-wrapper {
    width: 100%;
    padding-left: 30.6041vw;
  }

  .ki-about-info-section {
    margin-bottom: 4.166vw;
  }
  .ki-about-about {
    text-align: justify;
  }
}

@media (max-width: 767px) {
  .ki-about-top-text-section {
    padding-top: 4vw;
    padding-bottom: 4vw;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .ki-about-bottom-text-section {
    padding-right: 4vw;
    padding-left: 4vw;
    padding-bottom: 4vw;
  }

  .ki-about-details {

  }

  .ki-about-main-section {
    padding-top: 4vw;
  }

  .ki-about-headline {
    margin-bottom: 2.1875vw;
    text-align: start;
    text-align: left;
  }

  .ki-about-details > *:not(:last-child) {
    margin-bottom: 1.666666vw;
  }

  .ki-about-divider {
    width: 36vw;
    margin-top: 8vw;
    margin-bottom: 8vw;
  }

  .ki-about-divider-wrapper {
    width: 100%;

  }

  .ki-about-info-section {
    margin-bottom: 8vw;
  }
}

.ki-about-wrapper {
  position: relative;
  z-index: 3;
}

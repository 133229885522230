@media (min-width: 768px) {
  .ki-about-grid-square {
    width: 33.333vw;
    height: 19.097395vw;
  }
}

@media (max-width: 767px) {
  .ki-about-grid-square {
    width: 100vw;
    height: 100vw;
  }
}

@media (min-width: 768px) {
  .ki-dot {
    width: 1.17130vw;
    height: 1.17130vw;
  }
}

@media (max-width: 767px) {
  .ki-dot {
    width: 3.86473vw;
    height: 3.86473vw;
  }
}

.ki-dot {
  border-radius: 100%;
  transition-property: background-color;
  transition-duration: .5s;
}

.ki-dot:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .ki-group-textfield {
    width: 13.8541vw;
    border-radius: 4px;
    border: none;
  }
}

@media (max-width: 767px) {
  .ki-group-textfield {
    width: 53.8541vw;
    border-radius: 4px;
    border: none;
  }
}

.ki-group-textfield,
.ki-group-button {
  height: 100%;
}

.ki-group-button {
  border: none;
  background-color: transparent;
  padding-left: 1vw;
  padding-right: 1vw;
}

.ki-group-container {
  border-radius: 4px;
  border: 1px solid white;
}

.ki-group-textfield,
.ki-group-button {

}

.video-react-control-bar, .video-react-big-play-button {
  display: none;
}
/*
.video-react {
  height: 0px;
  position: relative;
  width: 100%;
}

.video-react-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
*/

.expt-play:hover {
  cursor: pointer;
}

.expt-play {
  width: 10vw;
}

.expt-play-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.expt-hero-video-hidden {
  display: none;
}


.video-react {

  max-height: 100vh;
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 0% !important;
}

.video-react-video {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.ki-menu-background {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .ki-menu-bubbles-br {
    width: 67.29166666vw;
  }
  .ki-menu-bubble-1-tl {
    width: 50vw;
  }

  .ki-menu-links {
    width: 50vw;
  }

  .ki-menu-links {
    padding-left: 7.70833vw;
    border-left: 5px solid #FFFFFF;
  }

  .ki-menu-bubble-magenta {
    width: 7.8125vw;
    top: 25.5208vw;
    left: 30.833vw;
  }

  .ki-menu-bubble-green {
    width: 4.16vw;
    top: 28.125vw;
    left: 0vw;
  }

  .ki-menu-bubble-purple {
    width: 5.20833vw;
    right: 3.75vw;
    top: 6.4583vw;
  }

  .ki-menu-copyright {
    padding-bottom: 4vw;
  }

}

@media (max-width: 767px) {
  .ki-menu-bubbles-br {
    width: 67.29166666vw;
  }
  .ki-menu-bubble-1-tl {
    width: 50vw;
  }

  .ki-menu-links {
    width: 90vw;
  }

  .ki-menu-links {
    padding-left: 7.70833vw;
    border-left: 5px solid #FFFFFF;
  }

  .ki-menu-bubble-magenta {
    width: 7.8125vw;
    top: 25.5208vw;
    left: 30.833vw;
  }

  .ki-menu-bubble-green {
    width: 4.16vw;
    top: 28.125vw;
    left: 0vw;
  }

  .ki-menu-bubble-purple {
    width: 5.20833vw;
    right: 3.75vw;
    top: 6.4583vw;
  }

  .ki-menu-copyright {
    padding-bottom: 4vw;
  }

}

.ki-menu-copyright {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.ki-menu-links {
  position: relative;
  z-index: 2;
  line-height: 1;
}

.ki-menu-links > *:not(:last-child) {
  margin-bottom: 3.802083vw;
}

.ki-menu-bubble-1-tl {
  top: 0;
  left: 0;
}

.ki-menu-container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
}

.ki-menu-bubbles-br {
  bottom: 0;
  right: 0;
}

.ki-menu-bubble-1-tl,
.ki-menu-bubbles-br,
.ki-menu-bubble-green,
.ki-menu-bubble-magenta,
.ki-menu-bubble-purple {
  position: absolute;
}

.ki-menu-hidden {
  display: none !important;
}

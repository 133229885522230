/* TODO:  add parameters for style attributes into code. */

@media (min-width: 768px) {
  .menuLines {
    top: 1.5vw;
    right: 2vw;
    height: 2.4479vw;
  }
  .menuLine1 {
    width: 3.6vw;
  }

  .menuLine2 {
    width: 1.8vw;
    top: 1.08vw;
  }

  .menuLine3 {
    width: 2.4vw;
    top: 2.16vw;
  }
  .menuLine1, .menuLine2, .menuLine3 {
    height: 5px;
  }
  .menuLine2.active {
    left: calc(100% - 1.8vw);
  }
  .menuLine3.active {
    left: calc(100% - 2.4vw);
  }
}

@media (max-width: 767px) {
  .menuLine2.active {
    left: calc(100% - 4.8vw);
  }
  .menuLine3.active {
    left: calc(100% - 6.4vw);
  }
  .menuLines {
    top: 2vw;
    right: 2vw;
    height: 15px;
  }
  .menuLine1 {
    width: 8.6vw;
  }

  .menuLine2 {
    width: 4.8vw;
    top: 3vw;
  }

  .menuLine3 {
    width: 6.4vw;
    top: 6.16vw;
  }

  .menuLine1, .menuLine2, .menuLine3 {
    height: 5px;
  }
}

.menuLine1, .menuLine2, .menuLine3 {
  border-radius: 3px;
  background-color: #fff;
}

.menuLines {
  position: absolute;
  z-index: 4;

}

.lineMenu {
  height: 48px;
  display: flex;
  align-items: flex-start;
  margin-top: auto;
  padding: 40px;
}

.menuLine2, .menuLine3 {
  position: absolute;
}



.menuLine2, .menuLine3 {
  left: 0%;
  transition-property: left;
  transition-duration: .2s;
}



/*.menuLine2.active:hover {
  width: 0%;
}*/

.menuLine2 {
  left: 0%;
}

.menuLines:hover {
  cursor: pointer;
}

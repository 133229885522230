@media (min-width: 768px) {
  .ki-madsane-container {
    margin-top: 5.20833vw;
  }
}

@media (max-width: 767px) {
  .ki-madsane-container {
    margin-top: 15vw;
  }
}

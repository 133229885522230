
@media (min-width: 768px) {
  .ki-divider {
    height: 0.416666vw;
  }
}


@media (max-width: 767px) {
  .ki-divider {
    height: 1vw;
  }

}

@media (min-width: 768px) {
  .ki-dots > *:not(:last-child) {
    margin-right: 1.1713vw;
  }
}


@media (max-width: 767px) {
  .ki-dots > *:not(:last-child) {
    margin-right: 3.86473vw;
  }
}


.expt-hero-dotsNav-container > *:not(:last-child) {
  margin-right: 1.04166vw;
}
